import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import MoreButton from '../Buttons/more';

const style = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 320,
    overflowY: "auto",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5
};
const styleSP = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: 400,
    // overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
}

  const PopupBetaLimit: React.FC<Props> = ({ handleClose, open, isMobile }) => {
   

  return (
    <Modal open={open} onClose={handleClose} sx={{ border: 'none' }}>
        <Fade in={open}>
            <Stack sx={isMobile ? styleSP : style}>
            {/* バツボタンを右上に配置するためのBox */}
            <Box
                sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 1301, // Modal のデフォルト zIndex (1300) より高い値を設定
                }}
            >
                <IconButton onClick={handleClose} sx={{cursor:"pointer"}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            
            <Stack
                width={isMobile ? '95%' : '90%'}
                spacing={4}
                p={5}
                alignItems={'center'}
                sx={{ backgroundColor: 'white', borderRadius: '20px' }}
            >
                <Typography sx={{ fontWeight: 'bold', color: '#363636', fontSize: 24 }}>
                【準備中】
                </Typography>

                <Typography
                sx={{
                    whiteSpace: 'pre-wrap',
                    lineHeight: 1.7,
                    fontWeight: 'bold',
                    color: '#363636',
                    fontSize: isMobile ? 20 : 18,
                }}
                >
                {
                    'この機能はβ版ではご利用頂けません。2025年5月リリース予定の完成版RE-IDEAをお楽しみに！！'
                }
                </Typography>
            </Stack>
            </Stack>
        </Fade>
    </Modal>

  );
};

export default PopupBetaLimit;